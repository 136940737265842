// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

require("@hotwired/turbo-rails")

Rails.start()

import "controllers"
import "web"
import "aos"

document.addEventListener(`turbo:load`, () => {
  document.documentElement.style.scrollBehavior = `smooth`
})

document.addEventListener(`turbo:before-visit`, () => {
  document.documentElement.style.scrollBehavior = `unset`
})

$(function () {
  update($('#web_contact_form_request_type'))
})
document.addEventListener(`turbo:render`, () => {
  update($('#web_contact_form_request_type'))
})
$(document).on('change', '#web_contact_form_request_type', function () {
  update(this)
})

function update (select) {
  var $select = $(select)
  var scope = $select.closest('form')
  var group = $select.val()
  var query = `[data-group~="${group}"], label[data-required~="${group}"] span`
  var negatedQuery = `[data-group]:not([data-group~="${group}"]), label[data-required]:not([data-required~="${group}"]) span`

  scope.find(negatedQuery).addClass('d-none')
  scope.find(query).removeClass('d-none')
}
