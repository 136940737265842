import Cookies from 'js-cookie'

;(function (window) {
  var SELECTOR = '[data-privacy-cookie]';
  var text = "";

  $(function() {
    var cookie = Cookies.get('here-map-privacy');
    var checked = (cookie == undefined) ? true : (cookie == 1);
    if(checked) {
      text='Derzeit werden Ihnen Karten von HERE Maps angezeigt und es werden Daten an HERE Maps übertragen.'
    } else {
      text='Es werden Ihnen keine Karten angezeigt. Es werden keine Daten an HERE Maps übertragen.'
    }
    $('#heremapsCookieInfo').html(text);
    $(SELECTOR).attr('checked', checked)

    $('[data-privacy-cookie]').on('click', function(e) {
      Cookies.set('here-map-privacy', Number($(this).prop('checked')));
      if(Cookies.get('here-map-privacy') == 0) {
        text='Es werden Ihnen keine Karten angezeigt. Es werden keine Daten an HERE Maps übertragen.'
      } else {
        text='Derzeit werden Ihnen Karten von HERE Maps angezeigt und es werden Daten an HERE Maps übertragen.'
      }
      $('#heremapsCookieInfo').html(text);
    })
  });
})(this)

window.enabledPrivacyCookie = function(name) {
  return (Cookies.get(name) == 0);
};
